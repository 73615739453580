<template>
  <v-container>
    <title-header :title="$route.meta.title" />
    <v-card class="table-page mt-5">
      <v-layout
        v-if="batchItems.length"
        class="inherit-height"
        d-flex
        flex-column
        justify-space-between
      >
        <v-data-table
          v-model="selectedBatchItems"
          :headers="headers"
          :items="batchItems"
          hide-default-footer
          :show-select="userCanManagePending"
          :items-per-page="tableInfo.itemsPerPage"
        />
        <pagination
          :currentPage="tableInfo.currentPage"
          :totalPages="tableInfo.totalPages"
          :itemsPerPage="tableInfo.itemsPerPage"
          @currentPageChanged="handleCurrentPageChange($event)"
          @itemsPerPageChanged="handleItemsPerPageChange($event)"
        />
      </v-layout>
      <div class="d-flex flex-column justify-center inherit-height" v-else>
        <pending-not-found
          :title="[
            'Você ainda não possui',
            'Nenhum material Pendente',
            'Aguarde!'
          ]"
        />
      </div>
    </v-card>
    <v-row justify="end" v-if="userCanManagePending && hasBatchItems">
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-btn
          color="secondary-color-1"
          class="next-step-button white--text"
          :disabled="!hasSelectedBatchItems || !userCanManagePending"
          block
          @click="openConfirmDestinyDialog"
        >
          Encaminhar para Área Designada
        </v-btn>
      </v-col>
    </v-row>
    <confirm-destiny-dialog
      :state="confirmDestinyDialogState"
      :fnConfirm="handleConfirmDestiny"
    />
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ConfirmDestinyDialog from "@/modules/stock/pending/views/components/ConfirmDestinyDialog";

  import PendingNotFound from "@/modules/stock/pending/views/components/PendingNotFound";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "ViewPending",

    components: {
      TitleHeader,
      Pagination,
      ConfirmDestinyDialog,
      PendingNotFound
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      confirmDestinyDialogState: {
        dialog: false
      },
      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batchNumber"
        },
        { text: "Data Registro", value: "createdAt" },
        { text: "Tipo", value: "typeDescription" },
        { text: "Peso Total", value: "weightTotal" },
        { text: "Área Destinada", value: "destiny" }
      ],
      batchItems: [],
      selectedBatchItems: []
    }),

    mounted() {
      this.updateData();
    },

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      hasBatchItems() {
        return this.batchItems?.length;
      },
      hasSelectedBatchItems() {
        return this.selectedBatchItems?.length;
      },
      userCanManagePending() {
        return this.routes?.some(
          route =>
            route.name === "pending" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/pending", ["getPending", "sendBatchItemsToDestiny"]),

      async fetchPending() {
        try {
          const payload = this.getPayloadData();

          const { data, meta } = await this.getPending(payload);

          this.setTableInfo(meta);
          this.batchItems = this.formatBatchItems(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatBatchItems(batchItems) {
        return batchItems.map(batchItem => {
          return {
            id: batchItem.id,
            batchNumber: batchItem.batch.batchNumber,
            createdAt: this.parseDate(batchItem.createdAt, "dd/MM/yyyy"),
            typeDescription: batchItem.typeDescription,
            weightTotal: this.safeParseWeight(batchItem.weightTotal),
            destiny: batchItem.destiny?.description ?? "não informado"
          };
        });
      },

      clearSelectedItems() {
        this.selectedBatchItems = [];
      },

      updateData() {
        this.fetchPending();
      },

      openConfirmDestinyDialog() {
        this.confirmDestinyDialogState.dialog = true;
      },

      mountPayloadSendDestiny() {
        return {
          batchItems: this.selectedBatchItems.map(
            selectedBatch => selectedBatch.id
          )
        };
      },

      async handleConfirmDestiny() {
        try {
          const payload = this.mountPayloadSendDestiny();
          await this.sendBatchItemsToDestiny(payload);
          this.clearSelectedItems();
          this.updateData();

          this.toggleSnackbar({
            text: "Itens encaminhados para a área designada",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style></style>
