<template>
  <data-not-found :title="title" :imgSrc="require('@/assets/pending.svg')" />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";
  export default {
    nome: "PendingNotFound",

    props: {
      title: {
        type: Array,
        required: true
      }
    },

    components: { DataNotFound }
  };
</script>

<style></style>
